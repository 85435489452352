var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page-wrapper"},[_c('div',{staticClass:"container-xl"},[_c('div',{staticClass:"page-header d-print-none"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"page-pretitle"},[_c('ol',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$auth.activeCompany.companyName)+" ")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/content"}},[_vm._v("Content Library")])],1)])]),(_vm.editing)?_c('h2',{staticClass:"page-title"},[_vm._v("Edit Content")]):_c('h2',{staticClass:"page-title"},[_vm._v("Add New Content")])]),_c('div',{staticClass:"col-12 col-md-auto ms-auto d-print-none"},[_c('div',{staticClass:"btn-list"},[_c('a',{class:['btn', 'btn-primary', 'd-inline-block'],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Save Content ")])])])])])]),(_vm.loaded && !_vm.saving)?_c('div',{staticClass:"page-body"},[_c('div',{staticClass:"container-xl"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[(_vm.content.contentType == null)?_c('content-type-picker',{class:[
              'form-control bg-transparent',
              _vm.$v.content.contentType.$error ? 'is-invalid' : 'border-0' ],attrs:{"db-content-type":_vm.content.contentTypeDb,"content-id":this.content.id,"change-type":this.changeType},model:{value:(_vm.content.contentType),callback:function ($$v) {_vm.$set(_vm.content, "contentType", $$v)},expression:"content.contentType"}}):_vm._e(),(!_vm.$v.content.contentType.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A content type is required. ")]):_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex flex-row justify-content-between mb-2"},[_c('span',[_vm._v("Upload "+_vm._s(_vm.content.contentType.name))]),_c('a',{staticClass:"btn btn-icon btn-sm btn-outline-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();(_vm.content.contentType = null), (_vm.changeType = true)}}},[_vm._v(" Change Content Type ")])]),_c('drag-and-drop-upload-card',{class:[
                'form-control',
                _vm.$v.content.file.$error ? 'is-invalid' : '' ],attrs:{"label":"content file","contentTypes":_vm.content.contentType.filetypes,"uniqueId":"content-file","uploadTo":"content"},model:{value:(_vm.content.file),callback:function ($$v) {_vm.$set(_vm.content, "file", $$v)},expression:"content.file"}}),(!_vm.$v.content.file.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A content file is required. ")]):_vm._e()],1),_c('div',{staticClass:"form-group mb-3 mt-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"content-name"}},[_vm._v("Title")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.content.title.$model),expression:"$v.content.title.$model"}],class:[
                'form-control',
                _vm.$v.content.title.$error ? 'is-invalid' : '' ],attrs:{"id":"content-name","type":"text","required":""},domProps:{"value":(_vm.$v.content.title.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.content.title, "$model", $event.target.value)}}}),(!_vm.$v.content.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A title is required. ")]):_vm._e()]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":"content-short-description"}},[_vm._v(" Content Description ")]),_c('b-form-textarea',{attrs:{"id":"content-short-description","state":_vm.$v.content.description.$error ? false : null,"rows":"4","max-rows":"12"},model:{value:(_vm.$v.content.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.content.description, "$model", $$v)},expression:"$v.content.description.$model"}}),(!_vm.$v.content.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A description is required. ")]):_vm._e(),(!_vm.$v.content.description.maxLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Description must be less than 2000 characters. (Currently: "+_vm._s(_vm.$v.content.description.$model.length)+") ")]):_vm._e()],1),_c('lead-contact',{model:{value:(_vm.content.leadContactId),callback:function ($$v) {_vm.$set(_vm.content, "leadContactId", $$v)},expression:"content.leadContactId"}})],1),_c('div',{staticClass:"col-md-6"},[_c('content-preview-image',{model:{value:(_vm.content.preview),callback:function ($$v) {_vm.$set(_vm.content, "preview", $$v)},expression:"content.preview"}}),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v(" Content Sites ")]),_c('site-select-list',{class:[
                'form-control bg-transparent',
                _vm.$v.content.categories.$error ? 'is-invalid' : 'border-0' ],attrs:{"content-remaining":_vm.contentRemaining},on:{"archive":_vm.archive},model:{value:(_vm.content.categories),callback:function ($$v) {_vm.$set(_vm.content, "categories", $$v)},expression:"content.categories"}}),(!_vm.$v.content.categories.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" A category is required. ")]):_vm._e()],1)],1)])])]):(_vm.saving)?_c('div',{staticClass:"page-body"},[_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-xl"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"progress progress-sm card-progress"},[_c('div',{staticClass:"progress-bar bg-green progress-bar-indeterminate"})]),_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v("Saving content")])])])])])])}]

export { render, staticRenderFns }