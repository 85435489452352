<template>
  <div class="mb-3">
    <label class="form-label">Preview Image</label>
    <drag-and-drop-upload-card
      :contentTypes="['jpg', 'jpeg', 'png', 'gif']"
      :inlinePreview="true"
      v-model="file"
      :uniqueId="`preview-image`"
      :label="`preview image`"
      :uploadTo="`images`"
      :resizeImage="true"
      :maxHeight="1550"
      :maxWidth="1200"
      :displayHeight="200"
      :displayWidth="156"
      :helpText="`Preview image should be 1200px wide by 1550px tall. We will use a screenshot of the first page if no image is provided.`"
    ></drag-and-drop-upload-card>
  </div>
</template>

<script>
import DragAndDropUploadCard from "./DragAndDropUploadCard.vue";
export default {
  components: { DragAndDropUploadCard },
  name: "content.upload.content-preview-image",
  props: {
    value: Object,
  },
  data() {
    let value = this.value;
    return {
      file: value,
    };
  },
  watch: {
    file() {
      this.$emit("input", this.file);
    },
  },
};
</script>
