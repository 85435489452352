export default {
  async getContentType(slug) {
    return (await this.getContentTypes()).filter((s) => s.slug == slug)[0];
  },
  async getContentTypes() {
    return [
      {
        id: 1,
        slug: "whitepaper",
        icon: `
              <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-file" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
              </svg>`,
        name: "WhitePaper",
        filetypes: ["pdf"],
      },
      {
        id: 2,
        slug: "ebook",
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-book-2" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z"></path>
          <path d="M19 16h-12a2 2 0 0 0 -2 2"></path>
          <path d="M9 8h6"></path>
        </svg>
        `,
        name: "eBook",
        filetypes: ["pdf"],
      },
      {
        id: 3,
        slug: "testimonial",
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-stars" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M17.8 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path>
          <path d="M6.2 19.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path>
          <path d="M12 9.817l-2.172 1.138a0.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a0.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a0.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a0.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a0.39 .39 0 0 1 -.567 .411l-2.172 -1.138z"></path>
        </svg>
        `,
        name: "Testimonial",
        filetypes: ["pdf"],
      },
      // {
      //   id: 4,
      //   slug: "podcast",
      //   icon: `
      //     <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-microphone" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      //       <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      //       <rect x="9" y="2" width="6" height="11" rx="3"></rect>
      //       <path d="M5 10a7 7 0 0 0 14 0"></path>
      //       <line x1="8" y1="21" x2="16" y2="21"></line>
      //       <line x1="12" y1="17" x2="12" y2="21"></line>
      //     </svg>
      //   `,
      //   name: "Podcast",
      //   filetypes: ["pdf"],
      // },
      // {
      //   id: 5,
      //   slug: "video",
      //   icon: `
      //   <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-video" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
      //     <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      //     <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z"></path>
      //     <rect x="3" y="6" width="12" height="12" rx="2"></rect>
      //   </svg>`,
      //   name: "Video",
      //   filetypes: ["mov", "mp4"],
      // },
      {
        id: 6,
        slug: "casestudy",
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-blockquote" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M6 15h15"></path>
          <path d="M21 19h-15"></path>
          <path d="M15 11h6"></path>
          <path d="M21 7h-6"></path>
          <path d="M9 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2"></path>
          <path d="M3 9h1a1 1 0 1 1 -1 1v-2.5a2 2 0 0 1 2 -2"></path>
        </svg>`,
        name: "Case Study",
        filetypes: ["pdf"],
      },
      {
        id: 7,
        slug: "infographic",
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" class="icon-tabler-chart-infographic" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="7" cy="7" r="4"></circle>
          <path d="M7 3v4h4"></path>
          <line x1="9" y1="17" x2="9" y2="21"></line>
          <line x1="17" y1="14" x2="17" y2="21"></line>
          <line x1="13" y1="13" x2="13" y2="21"></line>
          <line x1="21" y1="12" x2="21" y2="21"></line>
        </svg>`,
        name: "Infographic",
        filetypes: ["pdf", "jpg", "png"],
      },
    ];
  },
};
