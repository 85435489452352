<template>
  <div>
    <label class="form-label"> Choose Content Type </label>
    <div
      class="d-flex flex-row flex-wrap justify-content-center align-items-center contenttypes"
    >
      <div
        class="contenttype border me-3 mb-3 p-3 d-flex flex-column align-items-center bg-blue-lt"
        v-for="type in contentTypes"
        @click="select(type)"
        :key="`ct-${type.id}`"
      >
        <div class="content-icon">
          <span v-html="type.icon"></span>
        </div>
        <strong>{{ type.name }}</strong>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contenttype {
  border-radius: 4px;
  width: 125px;
  cursor: pointer;

  .content-icon {
    width: 32px;
    height: 32px;
  }

  &:hover {
    color: white !important;
    background: #206bc4 !important;
  }
}
</style>

<script>
import ContentTypeService from "../../../services/ContentTypeService";

export default {
  name: "content.upload.content-type-picker",
  props: {
    value: Object,
    dbContentType: String,
    contentId: Number,
    changeType: Boolean,
  },
  data() {
    return {
      contentTypes: [],
    };
  },
  methods: {
    unselect() {
      this.$emit("input", null);
    },
    select(content) {
      this.$emit("input", content);
    },
  },
  async mounted() {
    this.contentTypes = await ContentTypeService.getContentTypes();
    if (this.dbContentType) {
      let selected = this.contentTypes.filter(
        (c) => c.slug == this.dbContentType
      );
      if (selected.length > 0) {
        this.select(selected[0]);
      }
    } else if (!this.dbContentType && this.contentId && !this.changeType) {
      let selected = this.contentTypes.filter((c) => c.slug == "whitepaper");
      if (selected.length > 0) {
        this.select(selected[0]);
      }
    }
  },
};
</script>
