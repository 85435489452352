<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              <ol class="breadcrumb" aria-label="breadcrumbs">
                <li class="breadcrumb-item">
                  <router-link to="/">
                    {{ $auth.activeCompany.companyName }}
                  </router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link to="/content">Content Library</router-link>
                </li>
              </ol>
            </div>
            <h2 class="page-title" v-if="editing">Edit Content</h2>
            <h2 class="page-title" v-else>Add New Content</h2>
          </div>

          <div class="col-12 col-md-auto ms-auto d-print-none">
            <div class="btn-list">
              <a
                href="#"
                :class="['btn', 'btn-primary', 'd-inline-block']"
                @click.prevent="save"
              >
                Save Content
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loaded && !saving" class="page-body">
      <div class="container-xl">
        <div class="row">
          <div class="col-md-6">
            <content-type-picker
              :class="[
                'form-control bg-transparent',
                $v.content.contentType.$error ? 'is-invalid' : 'border-0',
              ]"
              v-model="content.contentType"
              :db-content-type="content.contentTypeDb"
              :content-id="this.content.id"
              :change-type="this.changeType"
              v-if="content.contentType == null"
            ></content-type-picker>
            <div
              class="invalid-feedback"
              v-if="!$v.content.contentType.required"
            >
              A content type is required.
            </div>
            <div class="mb-3" v-else>
              <div class="d-flex flex-row justify-content-between mb-2">
                <span>Upload {{ content.contentType.name }}</span>
                <a
                  href="#"
                  class="btn btn-icon btn-sm btn-outline-primary"
                  @click.prevent="
                    (content.contentType = null), (changeType = true)
                  "
                >
                  Change Content Type
                </a>
              </div>
              <drag-and-drop-upload-card
                :class="[
                  'form-control',
                  $v.content.file.$error ? 'is-invalid' : '',
                ]"
                v-model="content.file"
                :label="`content file`"
                :contentTypes="content.contentType.filetypes"
                :uniqueId="`content-file`"
                :uploadTo="`content`"
              ></drag-and-drop-upload-card>
              <div class="invalid-feedback" v-if="!$v.content.file.required">
                A content file is required.
              </div>
            </div>

            <div class="form-group mb-3 mt-3">
              <label class="form-label" for="content-name">Title</label>
              <input
                :class="[
                  'form-control',
                  $v.content.title.$error ? 'is-invalid' : '',
                ]"
                id="content-name"
                type="text"
                v-model="$v.content.title.$model"
                required
              />
              <div class="invalid-feedback" v-if="!$v.content.title.required">
                A title is required.
              </div>
            </div>

            <div class="form-group mb-3">
              <label class="form-label" for="content-short-description">
                Content Description
              </label>

              <b-form-textarea
                id="content-short-description"
                v-model="$v.content.description.$model"
                :state="$v.content.description.$error ? false : null"
                rows="4"
                max-rows="12"
              ></b-form-textarea>

              <div
                class="invalid-feedback"
                v-if="!$v.content.description.required"
              >
                A description is required.
              </div>

              <div
                class="invalid-feedback"
                v-if="!$v.content.description.maxLength"
              >
                Description must be less than 2000 characters. (Currently:
                {{ $v.content.description.$model.length }})
              </div>
            </div>

            <lead-contact v-model="content.leadContactId"></lead-contact>
          </div>
          <div class="col-md-6">
            <content-preview-image
              v-model="content.preview"
            ></content-preview-image>

            <div class="form-group mb-3">
              <label class="form-label"> Content Sites </label>
              <site-select-list
                :class="[
                  'form-control bg-transparent',
                  $v.content.categories.$error ? 'is-invalid' : 'border-0',
                ]"
                :content-remaining="contentRemaining"
                v-model="content.categories"
                @archive="archive"
              ></site-select-list>
              <div
                class="invalid-feedback"
                v-if="!$v.content.categories.required"
              >
                A category is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="saving" class="page-body">
      <div class="container-xl">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="card">
              <div class="progress progress-sm card-progress">
                <div
                  class="progress-bar bg-green progress-bar-indeterminate"
                ></div>
              </div>
              <div class="card-body">
                <h3 class="card-title text-center">Saving content</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ContentTypePicker from "../../components/Content/Upload/ContentTypePicker";
import SiteSelectList from "../../components/Content/Upload/SiteSelectList.vue";
import DragAndDropUploadCard from "../../components/Content/Upload/DragAndDropUploadCard.vue";
import WhitePaperService from "../../services/WhitePaperService";
import ContentTypeService from "../../services/ContentTypeService";
import TrackingService from "../../services/TrackingService";
import ContentPreviewImage from "../../components/Content/Upload/ContentPreviewImage.vue";
import LeadContact from "../../components/Content/Upload/LeadContact.vue";

function hasOneCategory() {
  let sites = Object.keys(this.content.categories);
  if (sites.length === 0) return false;

  let aCategory = false;
  for (let i = 0; i < sites.length; i++) {
    let siteCategories = this.content.categories[sites[i]].filter(
      (c) => c.saved
    ).length;

    aCategory = aCategory || siteCategories > 0;
  }

  return aCategory;
}

export default {
  name: "content.new-content",
  components: {
    ContentTypePicker,
    SiteSelectList,
    DragAndDropUploadCard,
    ContentPreviewImage,
    LeadContact,
  },
  data() {
    return {
      loaded: false,
      content: {
        id: null,
        contentTypeDb: null,
        contentType: null,
        file: null,
        preview: null,
        title: null,
        description: null,
        categories: {},
        company: null,
        leadContactId: null,
        changeType: false,
      },
      contentRemaining: {},
      editing: false,
      saving: false,
    };
  },
  validations: {
    content: {
      contentTypeDb: {
        required,
      },
      contentType: {
        required,
      },
      file: {
        required,
      },
      title: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(2000),
      },
      categories: {
        isComplete: hasOneCategory,
      },
    },
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.saving) return;

        this.saving = true;

        if (!this.content.company) {
          this.content.company = {
            companyId: this.$auth.activeCompany.companyId,
          };
        }

        TrackingService.track("saved changes to content", {
          detail: this.content.title,
        });

        await WhitePaperService.saveContent(this.content);
        await this.$router.push("/content");
      }
    },
    async archive(content) {
      let toArchive = content.content;
      let site = content.site;
      for (let i = 0; i < toArchive.length; i++) {
        await WhitePaperService.archiveWhitepaperSite(
          this.$auth.activeCompany.companyId,
          site.siteid,
          toArchive[i]
        );
        TrackingService.track("archived content", {
          siteId: site.siteid,
          detail: toArchive[i],
        });
      }
      this.contentRemaining = await WhitePaperService.getContentRemaining(
        this.$auth.activeCompany.companyId
      );
    },
  },
  watch: {
    "content.contentType"() {
      if (this.content.contentType && this.content.contentType.slug) {
        this.content.contentTypeDb = this.content.contentType.slug;
      } else {
        this.content.contentTypeDb = null;
      }
    },
  },
  async mounted() {
    this.contentRemaining = await WhitePaperService.getContentRemaining(
      this.$auth.activeCompany.companyId
    );
    this.changeType = false;
    if (this.$route.query.content) {
      let content = await WhitePaperService.getWhitePaper(
        this.$auth.activeCompany.companyId,
        this.$route.query.content
      );
      let categories = content.categories;
      Object.keys(categories).forEach((k) => {
        categories[k].map((o) => (o.saved = true));
      });
      this.content.id = content.id;
      this.content.title = content.title;
      this.content.description = content.description;
      this.content.categories = categories;
      this.content.file = content.file;
      this.content.preview = content.preview;
      this.content.contentTypeDb = content.contentTypeDb;
      this.content.contentType = await ContentTypeService.getContentType(
        content.contentTypeDb
      );
      this.content.company = content.company;
      this.content.leadContactId = content.leadContactId;
      this.editing = true;

      TrackingService.track("opened existing content for editing", {
        detail: this.content.title,
      });
    } else {
      TrackingService.track("started adding new content");
    }
    this.$nextTick(() => {
      this.loaded = true;
    });
  },
};
</script>
