<template>
  <div v-if="false">
    <label class="form-label">Lead Contact</label>
    <div
      class="form-check"
      v-for="lead in leadContactSelect"
      :key="`lead-${lead.value}`"
    >
      <input
        class="form-check-input"
        type="radio"
        name="leadcontact"
        :id="`lead-radio-${lead.value}`"
        :value="lead.value"
        v-model="selectedLead"
      />
      <label class="form-check-label" :for="`lead-radio-${lead.value}`">
        {{ lead.text }}
      </label>
    </div>
  </div>
</template>

<script>
import SettingsService from "../../../services/SettingsService";

export default {
  name: "content.upload.lead-contact",
  props: ["value"],
  data() {
    return {
      existingContacts: [],
      leadContactSelect: [],
      selectedLead: null,
    };
  },
  watch: {
    selectedLead() {
      this.$emit("input", this.selectedLead);
    },
  },
  methods: {
    async getContacts() {
      let company = this.$auth.activeCompany;

      let settingsFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let contactsDTO = await SettingsService.getContacts(settingsFilter);

      // populate leadContactSelect options
      contactsDTO.forEach((contact) => {
        this.leadContactSelect.push({
          text: contact.contactName,
          value: contact.contactID,
        });

        if (this.value == null) {
          this.selectedLead = contact.contactID;
        }
      });

      this.existingContacts = contactsDTO;
    },
  },
  async mounted() {
    this.selectedLead = this.value;
    await this.getContacts();
  },
};
</script>
